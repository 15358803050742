import { API_URL } from '../helpers/constants';
import { getUser } from '../utils/session';

export const fetchData = async (videoId, setData) => {
  const user = getUser();
  const res = await fetch(`${API_URL}/endoscopes/${videoId}/used`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.access_token}`
    }
  });
  const resJson = await res.json();
  setData(resJson);
};

export const fetchEndoscopeHistory = async ({ endoscopeId }) => {
  try {
    const user = getUser();
    const res = await fetch(`${API_URL}/endoscopes/${endoscopeId}/history`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.access_token}`
      }
    });
    const resJson = await res.json();
    return resJson;
  } catch (error) {
    console.log('Error', error);
    throw new Error(error);
  }
};
