import Select from 'react-select';
import { useAtom } from 'jotai';

import { companiesDataAtom, currentCompanyAtom } from '../atoms/companies';
import { generateSelectOptions, setSelectValue } from '../utils/helpers';

const CompanySelector = () => {
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [companiesData] = useAtom(companiesDataAtom);
  const options = generateSelectOptions(companiesData);

  const handleCompanysSelect = ({ value }) => {
    setSelectedCompany(value);
  };

  return (
    <Select
      name="Parent Company"
      placeholder="Please select company"
      options={options}
      isSearchable={false}
      value={setSelectValue(selectedCompany, options)}
      onChange={handleCompanysSelect}
      classNames={{
        singleValue: () => 'w-20 md:w-48',
        control: () => '[&&]:rounded-xl [&&]:cursor-pointer font-medium',
        option: () => '[&&]:cursor-pointer'
        // menu: () => '[&&]:z-50'
      }}
    />
  );
};

export default CompanySelector;
