import { useState } from 'react';
import { useAtom } from 'jotai';
import { Stepper, Step, Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { IoIosCloudUpload } from 'react-icons/io';
import { IoInformation } from 'react-icons/io5';

import { isLoadingAtom } from '../../atoms/app';
import Header from '../../components/Header/Header';
import Notification from '../../components/Notification';
import Loader from '../../components/Loader';
import InfoForm from './InfoForm';
import FileUpload from './FileUpload';

const VideoUpload = () => {
  const { t } = useTranslation(['common', 'buttons']);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <Header></Header>
      <Notification isTempopary={true} />
      {isLoading && <Loader type="absolute" blur={true} />}

      <div className="w-full px-48 pt-10 pb-20">
        <Stepper activeStep={activeStep}>
          <Step>
            <IoInformation className="h-5 w-5" />
            <div className="absolute -bottom-[4.5rem] w-max text-center">
              <Typography variant="h6" color={activeStep === 0 ? 'blue-gray' : 'gray'}>
                {t('common:step')} 1
              </Typography>
              <Typography color={activeStep === 0 ? 'blue-gray' : 'gray'} className="font-normal">
                {t('common:deviceInfo')}
              </Typography>
            </div>
          </Step>
          <Step>
            <IoIosCloudUpload className="w-5 h-5" />
            <div className="absolute -bottom-[4.5rem] w-max text-center">
              <Typography variant="h6" color={activeStep === 1 ? 'blue-gray' : 'gray'}>
                {t('common:step')} 2
              </Typography>
              <Typography color={activeStep === 1 ? 'blue-gray' : 'gray'} className="font-normal">
                {t('common:uploadVideo')}
              </Typography>
            </div>
          </Step>
        </Stepper>
      </div>
      {activeStep === 0 && <InfoForm setActiveStep={setActiveStep} />}
      {activeStep === 1 && <FileUpload maxFiles={1} setActiveStep={setActiveStep} />}
    </>
  );
};

export default VideoUpload;
