import cls from 'classnames';
import { Button } from '@material-tailwind/react';

const SpeedButton = ({ children, customStyle = '', onClick }) => {
  return (
    <Button
      color="white"
      className={cls(
        `flex justify-center mr-2 p-2 border max-w-[120px] border-blue-400 rounded-lg text-lg  ${customStyle}`
      )}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default SpeedButton;
