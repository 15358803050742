import { useMemo } from 'react';
import { Button, DialogBody, DialogFooter, Checkbox } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import { generateSelectOptions, formUserAccess } from '../../../utils/helpers';
import { setUserAccess } from '../../../api/users';
import { modalAtom, isLoadingAtom, notificationAtom } from '../../../atoms/app';
import { companiesDataAtom } from '../../../atoms/companies';
import { refrestUsersAtom } from '../../../atoms/users';

const AccessForm = ({ userAccess, userId, setUserStateAccess, defaultValues }) => {
  const { t } = useTranslation(['common', 'buttons']);

  const [companiesData] = useAtom(companiesDataAtom);
  const options = useMemo(() => generateSelectOptions(companiesData), [companiesData]);

  const [modal, setModal] = useAtom(modalAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [refresh, setRefresh] = useAtom(refrestUsersAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { parent_company, access } = userAccess;

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { isDirty }
  } = useForm({
    defaultValues
  });

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
    setUserStateAccess({});
  };

  const onSubmit = async (data, event) => {
    try {
      setIsLoading(true);
      const payload = { parent_id: data.parentCompany.value, companies: formUserAccess(data) };
      await setUserAccess(userId, payload);
      setIsLoading(false);
      handleClose();
      setNotification({ isOpen: true, type: 'success', message: t('common:userUpdated') });
      setRefresh((prev) => prev + 1);
      setUserStateAccess({});
    } catch (e) {
      console.log('submit error', e);
      setIsLoading(false);
      handleClose();
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  const watchParentCompany = watch('parentCompany', defaultValues.parentCompany);

  return (
    <form className="h-[600px]" onSubmit={handleSubmit(onSubmit)}>
      <DialogBody className="text-lg">
        <div className="flex justify-between items-center">
          <label>{t('parentCompany')}:</label>
          <Controller
            name="parentCompany"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                isSearchable={false}
                onChange={(value) => {
                  setValue(value.value, true);
                  setValue(watchParentCompany.value, false);
                  return setValue('parentCompany', value, { shouldDirty: true });
                }}
                classNames={{
                  singleValue: () => 'w-56',
                  control: () => '[&&]:cursor-pointer font-medium',
                  option: () => '[&&]:cursor-pointer'
                }}
              />
            )}
          />
        </div>
        <div className="mt-4 h-[448px] overflow-y-auto border-neutral-100 border-opacity-100 border-b-2 border-t-2">
          {access?.map((c, i) => (
            <div key={c.id} className="flex">
              <Controller
                name={c.id}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    disabled={c.id === watchParentCompany?.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    label={c.company_name}
                  />
                )}
              />
            </div>
          ))}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="red" onClick={handleClose} className="mr-6">
          {t('buttons:cancel')}
        </Button>
        <Button type="submit" variant="gradient" color="green" disabled={!isDirty}>
          {t('buttons:update')}
        </Button>
      </DialogFooter>
    </form>
  );
};

export default AccessForm;
