import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-tailwind/react';
import { IoIosCloudUpload } from 'react-icons/io';

const FileInfo = ({ icon }) => {
  const { t } = useTranslation(['common', 'buttons']);
  return (
    <div className="flex flex-col items-center w-full p-10 pt-14 cursor-pointer bg-white border border-dashed border-gray-300">
      <IoIosCloudUpload className="h-16 w-16 mb-4" />
      <Typography variant="h6" color="blue-gray">
        {t('buttons:dropFile')}
      </Typography>
      <Typography className="mb-4">{t('common:maxVideo')}</Typography>
      <Button variant="gradient" color={'light-blue'} className={`my-2 rounded-2xl `}>
        {t('buttons:orChoose')}
      </Button>
    </div>
  );
};

export default FileInfo;
