import { useEffect } from 'react';
import { Input, Textarea, Checkbox, Button } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { sidePanelAtom, isLoadingAtom, notificationAtom } from '../../atoms/app';
import { reportFramesAtom } from '../../atoms/video';
import { shareVideo } from '../../api/share';
import { getVideoId } from '../../utils/helpers';
import { formEmailsArray, isAtleastOneInvalidEmail } from '../../utils/validation';
import Loader from '../../components/Loader';
import FormError from '../../components/FormError/FormError';

const SidePanel = () => {
  const { t } = useTranslation(['buttons', 'common']);
  const [isSidePanelOpen, setIsSidePanelOpen] = useAtom(sidePanelAtom);
  const [reportFrames] = useAtom(reportFramesAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const handleCloseSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  const onSubmit = async (data, event) => {
    try {
      setIsLoading(true);
      const videoId = getVideoId();
      event.preventDefault();
      const emails = formEmailsArray(data.emails);
      if (reportFrames.id && data.att_report && !reportFrames.draft) {
        data.report_id = reportFrames.id;
      }

      await shareVideo({ ...data, emails }, videoId);
      handleCloseSidePanel();
      setIsLoading(false);
      setNotification({ isOpen: true, type: 'success', message: t('common:infoShared') });
    } catch (e) {
      console.log('Error', e);
      setIsLoading(false);
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  const handleKeydown = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      handleCloseSidePanel();
    };
  }, []);

  return (
    <div className="p-4 w-full bg-white sticky top-[113px] border-l-2 border-gray-200 min-h-[calc(100vh-113px)]">
      {isLoading && <Loader />}
      {!isLoading && (
        <form>
          <div className="relative mt-10 mb-6">
            <Input
              required
              color="blue"
              variant="outlined"
              label={t('common:emails')}
              placeholder={t('common:addEmailPeople')}
              multiple
              onKeyDown={handleKeydown}
              {...register('emails', {
                required: { value: true, message: t('common:fieldRequired') },
                validate: {
                  pattern: (value) => !isAtleastOneInvalidEmail(value) || t('common:emailsInvalid')
                }
              })}
            />
            <FormError text={errors?.emails?.message} isVisible={errors?.emails} />
          </div>
          <div className="mb-6">
            <Textarea
              {...register('comment')}
              color="blue"
              variant="outlined"
              onKeyDown={handleKeydown}
              label={t('common:comments')}
            />
          </div>
          <div className="mt-10 mb-6 flex items-center">
            <Checkbox
              label={t('common:includeInfo')}
              onKeyDown={handleKeydown}
              {...register('insp_info')}
            />
          </div>
          <div className="mb-6 flex items-center">
            <Checkbox
              label={t('common:attachReport')}
              disabled={!reportFrames.id || reportFrames.draft}
              onKeyDown={handleKeydown}
              {...register('att_report')}
            />
          </div>
          <div className="flex justify-between mt-40">
            <Button onClick={handleCloseSidePanel} variant="gradient" color="red">
              {t('buttons:cancel')}
            </Button>
            <Button onClick={handleSubmit(onSubmit)} type="submit" variant="gradient" color="green">
              {t('buttons:sendEmail')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SidePanel;
