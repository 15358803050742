import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
  Input
} from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';

import { getUser } from '../../../utils/session';
import { updateUser } from '../../../api/users';
import { modalAtom, isLoadingAtom, notificationAtom } from '../../../atoms/app';
import { isAtleastOneInvalidEmail } from '../../../utils/validation';
import { refrestUsersAtom } from '../../../atoms/users';
import Loader from '../../../components/Loader';

const EditUserModal = ({ size, defaultValues }) => {
  const { t } = useTranslation(['common', 'buttons']);
  const user = getUser();
  const [modal, setModal] = useAtom(modalAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [refresh, setRefresh] = useAtom(refrestUsersAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { name, isOpen, data } = modal;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, dirtyFields, isValid }
  } = useForm({
    defaultValues
  });

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
    reset();
  };

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      const { email, username, firstName: first_name, lastName: last_name } = data;
      setIsLoading(true);
      await updateUser(user.user_id, { username, first_name, last_name, email });
      setIsLoading(false);
      handleClose();
      setNotification({ isOpen: true, type: 'success', message: t('common:userUpdated') });
      setRefresh((prev) => prev + 1);
    } catch (e) {
      console.log('submit error', e);
      setIsLoading(false);
      handleClose();
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen && name === 'editUser'} size={size || 'sm'} handler={handleClose}>
          {isLoading && <Loader />}
          {!isLoading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogHeader className="justify-between">
                {t('common:editUser')}
                <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
                  <RxCross2 className="w-4 h-4" />
                </IconButton>
              </DialogHeader>
              <DialogBody className="text-lg">
                <div className="relative mb-4">
                  <Input
                    required
                    id="username"
                    color="blue"
                    label={t('common:userName')}
                    {...register('username', { required: true })}
                  />
                  {errors?.userName?.type === 'required' && (
                    <span className="text-red-600 text-sm">{t('common:fieldRequired')}</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <Input
                    required
                    id="firstName"
                    color="blue"
                    label={t('common:firstName')}
                    {...register('firstName', { required: true })}
                  />
                  {errors?.userName?.type === 'required' && (
                    <span className="text-red-600 text-sm">{t('common:fieldRequired')}</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <Input
                    required
                    id="lastName"
                    color="blue"
                    label={t('common:lastName')}
                    {...register('lastName', { required: true })}
                  />
                  {errors?.userName?.type === 'required' && (
                    <span className="text-red-600 text-sm">{t('common:fieldRequired')}</span>
                  )}
                </div>
                <div className="relative mb-4">
                  <Input
                    required
                    id="email"
                    color="blue"
                    label={t('common:email')}
                    {...register('email', {
                      required: true,
                      validate: (value) => !isAtleastOneInvalidEmail(value)
                    })}
                  />
                  {errors?.userName?.type === 'required' && (
                    <span className="text-red-600 text-sm">{t('common:fieldRequired')}</span>
                  )}
                  {errors?.email?.type === 'validate' && (
                    <span className="text-red-600 text-sm">{t('common:emailInvalid')}</span>
                  )}
                </div>
              </DialogBody>
              <DialogFooter>
                <Button variant="gradient" color="red" onClick={handleClose} className="mr-6">
                  {t('buttons:cancel')}
                </Button>
                <Button type="submit" variant="gradient" color="green" disabled={!isDirty}>
                  {t('buttons:confirm')}
                </Button>
              </DialogFooter>
            </form>
          )}
        </Dialog>
      )}
    </>
  );
};

export default EditUserModal;
