import { Button, DialogBody, DialogFooter, Textarea } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useForm, Controller } from 'react-hook-form';
import DateTimePicker from 'react-datetime-picker';
import { startOfTomorrow, addDays, parseISO } from 'date-fns';

import { formatYearMonthDayWithHours } from '../../../utils/date';
import { addSubscription } from '../../../api/subscriptions';
import { loadableSubscriptions, refreshSubscriptionsAtom } from '../../../atoms/subscriptions';
import { modalAtom, isLoadingAtom, notificationAtom } from '../../../atoms/app';
import { currentCompanyAtom } from '../../../atoms/companies';
import FormError from '../../FormError/FormError';

const SubscriptionForm = ({}) => {
  const { t } = useTranslation(['common', 'buttons']);
  const [subscriptions] = useAtom(loadableSubscriptions);
  const [currentCompany] = useAtom(currentCompanyAtom);
  const [modal, setModal] = useAtom(modalAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [refresh, setRefresh] = useAtom(refreshSubscriptionsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid }
  } = useForm({});

  const subscriptionsData = subscriptions?.data ? Object.entries(subscriptions?.data) : [];
  const subsEnd =
    subscriptionsData.length > 0
      ? subscriptionsData[subscriptionsData.length - 1][1].subs_end
      : null;
  const minDateFrom = subsEnd ? addDays(parseISO(subsEnd), 1) : startOfTomorrow();
  const watchFrom = watch('from');
  const minDateTo = addDays(watchFrom, 1);

  const handleClose = () => {
    setModal({ name: '', isOpen: false, data: {} });
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      await addSubscription(currentCompany, {
        comment: data.comment || null,
        date_from: formatYearMonthDayWithHours(data.from),
        date_to: formatYearMonthDayWithHours(data.to)
      });
      setRefresh((prev) => prev + 1);
      handleClose();
      setIsLoading(false);
      setNotification({ isOpen: true, type: 'success', message: t('common:subscriptionAdded') });
    } catch (e) {
      console.log('submit error', e);
      setIsLoading(false);
      handleClose();
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <DialogBody className="text-lg">
        <div className="mb-4">
          <div className="flex flex-wrap">
            <label className="pb-2 w-full">{t('common:from')}:</label>
            <Controller
              name="from"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  ref={null}
                  clearIcon={null}
                  defaultActiveStartDate={minDateFrom}
                  // calendarClassName="rounded-2xl"
                  format="MM dd, yyyy"
                  disableClock={true}
                  minDate={minDateFrom}
                  onChange={(value) => {
                    setValue('to', undefined);
                    return field.onChange(value);
                  }}
                />
              )}
              rules={{
                required: true
              }}
            />
          </div>
          <FormError
            text={t('common:fieldRequired')}
            isVisible={errors?.from?.type === 'required'}
          />
        </div>
        <div className="mb-4">
          <div className="flex flex-wrap">
            <label className="pb-2 w-full">{t('common:to')}:</label>
            <Controller
              name="to"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  ref={null}
                  // calendarClassName="rounded-2xl"
                  disabled={!watchFrom}
                  format="MM dd, yyyy"
                  disableClock={true}
                  clearIcon={null}
                  defaultActiveStartDate={minDateTo}
                  minDate={minDateTo}
                />
              )}
              rules={{
                required: true
              }}
            />
          </div>
          <FormError text={t('common:fieldRequired')} isVisible={errors?.to?.type === 'required'} />
        </div>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              color="blue"
              variant="outlined"
              label={t('common:comment')}
              className="flex"
            />
          )}
        />
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="red" onClick={handleClose} className="mr-6">
          {t('buttons:cancel')}
        </Button>
        <Button type="submit" variant="gradient" color="green">
          {t('buttons:add')}
        </Button>
      </DialogFooter>
    </form>
  );
};

export default SubscriptionForm;
