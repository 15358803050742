import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-tailwind/react';
import { RxCross2 } from 'react-icons/rx';
import { TiTick } from 'react-icons/ti';

import FileInput from '../../components/FileInput/FileInput';
import FileInfo from '../../components/FileInput/FileInfo';
import { uploadIdAtom } from '../../atoms/video';
import { isLoadingAtom, notificationAtom } from '../../atoms/app';
import { uploadVideo } from '../../api/video';
import { maxVideoSize } from '../../helpers/constants';

const FileUpload = ({ setActiveStep }) => {
  const { t } = useTranslation(['common', 'buttons']);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [uploadId, setUploadId] = useAtom(uploadIdAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [files, setFile] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const isFileUploaded = files?.length > 0;

  const handleFileRemove = (e) => {
    e.stopPropagation();
    setFile(null);
  };

  const handleVideoUpload = async (e) => {
    try {
      setIsLoading(true);
      await uploadVideo(uploadId, files[0]);
      setIsSuccess(true);
      // setNotification({ isOpen: true, type: 'success', message: t('common:fileUploaded') });
      setUploadId('');
      setIsLoading(false);
      // setActiveStep(0);
    } catch (error) {
      setIsLoading(false);
      console.log('upload video error', error);
      setNotification({
        isOpen: true,
        type: 'failure',
        message: error.message || t('common:somethingWrong')
      });
    }
  };

  return (
    <div className="flex flex-col px-48">
      {isSuccess ? (
        <div className="flex flex-col self-center items-center mt-10">
          <div className="flex items-center justify-center rounded-full bg-green-500 h-24 w-24">
            <TiTick className="text-5xl text-white" />
          </div>
          <Typography className="mt-4 text-center">{t('common:fileUploaded')}</Typography>
          <Typography
            onClick={() => setActiveStep(0)}
            className="mt-1 cursor-pointer text-center text-blue-600 hover:text-blue-400"
          >
            {t('common:uploadAnother')}
          </Typography>
        </div>
      ) : (
        <>
          <div className="flex justify-center mt-10">
            <div style={{ width: '416px' }}>
              <FileInput
                maxSize={maxVideoSize} //2GB
                onDrop={setFile}
                maxFiles={1}
                accept={{
                  'video/mp4': ['.mp4'],
                  'video/ogg': ['.ogv'],
                  'video/webm': ['.webm']
                }}
              >
                <FileInfo />
                <div className="flex items-center h-7 justify-center mt-5">
                  {isFileUploaded && (
                    <>
                      <Typography className="text-green-500 mr-4">{files[0].name}</Typography>
                      <RxCross2
                        onClick={handleFileRemove}
                        className="cursor-pointer text-red-300 hover:text-red-500"
                      />
                    </>
                  )}
                </div>
              </FileInput>
            </div>
          </div>
          <Button
            className="self-end mt-16"
            onClick={handleVideoUpload}
            disabled={!isFileUploaded}
            variant="gradient"
            color="green"
          >
            {t('buttons:addVideo')}
          </Button>
        </>
      )}
    </div>
  );
};

export default FileUpload;
