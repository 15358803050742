import React, { useEffect, useRef, memo } from 'react';
import * as d3 from 'd3';

function DraggableTimeIndicator({ xScale, timeIndicator, height, callback }) {
  const groupRef = useRef();

  useEffect(() => {
    const group = d3.select(groupRef.current);

    group.call(
      d3
        .drag()

        .on('start', function (e) {
          d3.select(this).raise().classed('active', true);
        })

        .on('drag', function (e) {
          const newX = Math.min(
            xScale.range()[1], // Maximum draggable x based on your scale
            Math.max(xScale.range()[0], e.x) // Minimum draggable x
          );

          d3.select(this).select('line').attr('x1', newX).attr('x2', newX);
          d3.select(this)
            .select('rect')
            .attr('x', newX - 15);

          d3.select(this)
            .select('svg')
            .attr('x', newX - 4);

          if (e.sourceEvent.type === 'mousemove') {
            callback(xScale.invert(newX));
          }
        })

        .on('end', function (e) {
          if (e.sourceEvent.type === 'touchend') {
            const newX = Math.min(
              xScale.range()[1], // Maximum draggable x based on your scale
              Math.max(xScale.range()[0], e.x) // Minimum draggable x
            );
            callback(xScale.invert(newX));
          }

          d3.select(this).classed('active', false);
        })
    );

    return () => {
      group.on('drag', null);
    };
  }, []);
  const currentPosition = xScale(timeIndicator);

  return (
    <g ref={groupRef} className="grabbable">
      <rect x={currentPosition - 9} y={0} width="18" height={height} fill="transparent" />
      <line
        x1={currentPosition}
        y1={height}
        x2={currentPosition}
        y2="0"
        stroke="red"
        strokeWidth="2"
      />
      <svg
        x={currentPosition - 4}
        y={height - 12}
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="white"
        stroke="red"
      >
        <path
          d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H7C7.27614 0.5 7.5 0.723858 7.5 1V8.25339C7.5 8.40336 7.43269 8.54541 7.31662 8.64037L4.31662 11.0949C4.13244 11.2456 3.86756 11.2456 3.68338 11.0949L0.683381 8.64037C0.567313 8.54541 0.5 8.40336 0.5 8.25339V1Z"
          transform="rotate(180, 4, 6)"
        />
      </svg>
    </g>
  );
}

const MemoizedComponent = memo(DraggableTimeIndicator, (prevProps, nextProps) => {
  return (
    prevProps.timeIndicator === nextProps.timeIndicator && prevProps.height === nextProps.height
  );
});

export default MemoizedComponent;
