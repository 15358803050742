import { useState, useCallback, useEffect } from 'react';

export default function useWidth(elementRef, dependencies = []) {
  const [width, setWidth] = useState(null);

  const updateWidth = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [elementRef]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  useEffect(() => {
    updateWidth();
  }, [updateWidth, ...dependencies]);

  return [width, setWidth];
}
