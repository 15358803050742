import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Collapse, IconButton } from '@material-tailwind/react';
import cls from 'classnames';
import { FaBars } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';

import CompanySelector from '../CompanySelector';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import UserDropdown from '../UserDropdown/UserDropdown';
import { companiesDataAtom, currentCompanyAtom } from '../../atoms/companies';
import { fetchCompanies } from '../../api/companies';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import NavList from '../NavList/NavList';
import { tabletResolution } from '../../helpers/constants';

export default function Header() {
  const [companiesData, setCompaniesData] = useAtom(companiesDataAtom);
  const [selectedCompany] = useAtom(currentCompanyAtom);
  const [openNav, setOpenNav] = useState(false);
  const companyVideoUrl = '/videos/' + selectedCompany;

  const headerRef = useRef(null);

  useOutsideClick(headerRef, () => setOpenNav(false));

  const handleMenuClick = (e) => {
    setOpenNav((cur) => !cur);
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= tabletResolution && setOpenNav(false)
    );

    fetchCompanies(setCompaniesData);

    return () => {
      window.removeEventListener(
        'resize',
        () => window.innerWidth >= tabletResolution && setOpenNav(false)
      );
    };
  }, []);

  return (
    <header ref={headerRef}>
      <div className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to={companyVideoUrl} className="flex h-20 items-center">
            <img src="/logo2.png" className="h-12 mx-4" alt="Verify AI Logo" />
          </Link>
          <div className="hidden 2xl:flex items-center">
            <NavList selectedCompany={selectedCompany} />
          </div>

          <div className="">{companiesData && <CompanySelector />}</div>

          <div className="items-center flex ">
            <LanguageSelector />
            <UserDropdown />

            <IconButton
              variant="text"
              color="blue-gray"
              className="2xl:hidden ml-4"
              onClick={handleMenuClick}
            >
              <>
                <RxCross2 className={cls(`h-6 w-6 hidden`, { '[&&]:block': openNav })} />
                <FaBars className={cls(`h-6 w-6`, { '[&&]:hidden': openNav })} />
              </>
            </IconButton>
          </div>
        </div>

        <Collapse open={openNav}>
          <NavList selectedCompany={selectedCompany} />
        </Collapse>
      </div>
    </header>
  );
}
