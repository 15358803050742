import { useEffect, useRef, useState, useCallback } from 'react';
import { Switch } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { FaRegKeyboard } from 'react-icons/fa';
import cls from 'classnames';

import { getUser } from '../../utils/session';
import { fetchData } from '../../api/endoscopes';
import { getVideoId } from '../../utils/helpers';
import { getCoordinates } from '../../utils/numbers';
import { API_URL } from '../../helpers/constants';
import { endoscopesAtom } from '../../atoms/endoscopes';
import {
  videoTypeAtom,
  framesAtom,
  frameAtom,
  reportModeAtom,
  isReportingAtom,
  durationAtom,
  isPausedAtom,
  mediaTimeAtom,
  reportFramesAtom,
  currentTimeAtom,
  isVideoLoadingAtom
} from '../../atoms/video';
import Tooltip from '../../components/Tooltip/Tooltip';
import Loader from '../../components/Loader';
import ShortcutsInfo from '../../components/ShortcutsInfo/ShortcutsInfo';
import BoundedBoxes from '../BoundedBoxes';
import Controls from './Controls';
import ControlsToolbar from './ControlsToolbar';
import Speed from './Speed';
import Box from '../Box';

const VideoPlayer = ({ isSidePanelOpen }) => {
  const user = getUser();
  const { t } = useTranslation(['common', 'buttons']);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [isVideoLoading, setIsVideoLoading] = useAtom(isVideoLoadingAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [data, setData] = useAtom(endoscopesAtom);
  const [videoType, setVideoType] = useAtom(videoTypeAtom);
  const [duration, setDuration] = useAtom(durationAtom);
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  const [frames, setFrames] = useAtom(framesAtom);
  const [frame, setFrame] = useAtom(frameAtom);
  const [mediaTime, setMediaTime] = useAtom(mediaTimeAtom);
  const [reportFrames, setReportFrames] = useAtom(reportFramesAtom);

  const [isPaused, setIsPaused] = useAtom(isPausedAtom);

  const { fps } = data;
  const [isToggleVisible, setIsToggleVisible] = useState(false);

  const videoRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [boundingClientRect, setBoundingClientRect] = useState({});

  const [initialPoints, setInitialPoints] = useState(null);
  const [finalPoints, setFinalPoints] = useState(null);

  let videoUrl;
  const videoId = getVideoId();
  const video = document.querySelector('video');

  const isPlaying =
    videoRef?.current?.currentTime > 0 &&
    !videoRef?.current?.paused &&
    !videoRef?.current?.ended &&
    videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA;

  const offsetHeight = videoRef?.current?.offsetHeight || 0;
  const offsetWidth = videoRef?.current?.offsetWidth || 0;

  useEffect(() => {
    videoUrl = `${API_URL}/video/stream/${videoType}/${videoId}?token=${user?.access_token}` || '';
    setIsVideoLoading(true);

    if (!isPaused) {
      setIsPaused(true);
    }

    if (duration) {
      setDuration(0);
    }
    setCurrentTime(0);

    let source = document.querySelector('video > source');

    source.src = videoUrl;

    source?.addEventListener('error', handleVideoLoadError);

    source.parentNode.load();

    return () => {
      source.removeEventListener('error', handleVideoLoadError);
    };
  }, [videoType, videoId]);

  useEffect(() => {
    let source = document.querySelector('video > source');
    source.parentNode?.addEventListener('loadeddata', handleVideoLoadSuccess);

    return () => {
      source.parentNode.removeEventListener('loadeddata', handleVideoLoadSuccess);
    };
  }, [isToggleVisible]);

  useEffect(() => {
    window.addEventListener('touchmove', handleMoveTouch, { passive: false });

    return () => {
      window.removeEventListener('touchmove', handleMoveTouch);
    };
  }, [isDrawing]);

  useEffect(() => {
    if (isToggleVisible) {
      setIsToggleVisible(false);
    }
    handleExitReportMode();

    fetchData(videoId, setData);
    setReportFrames({ id: null, frames: {} });

    return () => {
      setMediaTime(0);
      setFrame(0);
    };
  }, [videoId]);

  const handleVideoLoadError = (e) => {
    console.log('<source> video load error', videoType);
    // e does not contain anything useful -- https://html.spec.whatwg.org/multipage/media.html#e-source-error
    // e.target would be the <source> element
    // e.target.parentNode would be the <video> element
    // e.target.parentNode.error -- https://html.spec.whatwg.org/multipage/media.html#mediaerror
    // e.target.parentNode.networkState -- https://html.spec.whatwg.org/multipage/media.html#dom-media-networkstate
    setIsVideoLoading(false);
    if (e.target.parentNode.networkState !== 0 && videoType === 'wt') {
      setVideoType('wo');
    }
  };

  const handleVideoLoadSuccess = (e) => {
    setDuration(videoRef?.current?.duration);
    setIsVideoLoading(false);
    if (!isToggleVisible) {
      setIsToggleVisible(true);
    }
  };

  const handleMoveTouch = (e) => {
    if (isDrawing) {
      e.preventDefault();
    }
  };

  const handleExitReportMode = () => {
    setFrames({});
    if (reportMode) {
      setReportMode(false);
    }
    if (isReporting) {
      setIsReporting(false);
    }
  };

  const handleMouseDown = (e) => {
    if (reportMode) {
      const curFrame = frames[frame];
      // User cannot select another bounded box without selecting a label of the previous one
      if (!curFrame || curFrame?.length === 0 || curFrame[curFrame?.length - 1]?.label) {
        if (!isReporting) {
          setIsReporting(true);
        }

        pauseFunction();

        const { offsetX, offsetY } = e.nativeEvent;

        setInitialPoints({ x: offsetX, y: offsetY });
        setIsDrawing(true);
      }
    }
  };

  const handleTouchStart = (e) => {
    if (reportMode) {
      const curFrame = frames[frame];
      // User cannot select another bounded box without selecting a label of the previous one
      if (!curFrame || curFrame?.length === 0 || curFrame[curFrame?.length - 1]?.label) {
        if (!isReporting) {
          setIsReporting(true);
        }

        pauseFunction();

        const { x, y, width, height } = e.target.getBoundingClientRect();
        const offsetXPre = e.touches[0].clientX - x;
        const offsetYPre = e.touches[0].clientY - y;

        let offsetX = offsetXPre - width < 0 ? offsetXPre : width;
        let offsetY = offsetYPre - height < 0 ? offsetYPre : height;
        if (offsetX < 0) {
          offsetX = 0;
        }
        if (offsetY < 0) {
          offsetY = 0;
        }

        setInitialPoints({ x: offsetX, y: offsetY });
        setBoundingClientRect({ x, y, width, height });
        setIsDrawing(true);
      }
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing && !initialPoints) return;
    const { offsetX, offsetY } = e.nativeEvent;

    setFinalPoints({ x: offsetX, y: offsetY });
  };

  const handleTouchMove = (e) => {
    if (!isDrawing && !initialPoints) return;

    const { x, y, width, height } = boundingClientRect;
    let offsetX = e.touches[0].clientX - x;
    let offsetY = e.touches[0].clientY - y;

    offsetX = offsetX - width < 0 ? offsetX : width;
    offsetY = offsetY - height < 0 ? offsetY : height;
    if (offsetX < 0) {
      offsetX = 0;
    }
    if (offsetY < 0) {
      offsetY = 0;
    }

    setFinalPoints({ x: offsetX, y: offsetY });
  };

  const handleMouseUp = () => {
    if (isDrawing && initialPoints && finalPoints) {
      const newRectangle = {
        x_min: getCoordinates(initialPoints.x, offsetWidth),
        y_min: getCoordinates(initialPoints.y, offsetHeight),
        x_max: getCoordinates(finalPoints.x, offsetWidth),
        y_max: getCoordinates(finalPoints.y, offsetHeight)
      };
      setFrames((prevRectangles) => ({
        ...prevRectangles,
        [frame]: [...(prevRectangles[frame] || []), newRectangle]
      }));
    }
    setIsDrawing(false);
    setInitialPoints(null);
    setFinalPoints(null);
    setBoundingClientRect({});
  };

  const handleKeydown = (e) => {
    if (e.key == ' ' || e.keyCode === 32 || e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      e.stopPropagation();
    }
  };

  const pauseFunction = () => {
    if (!videoRef?.current?.paused && isPlaying) {
      videoRef.current.pause();
    }
  };

  const playFunction = () => {
    if (!isPlaying) {
      videoRef.current.play();
    }
  };

  const handleVideoClick = () => {
    if (isReporting) {
      return;
    }
    if (isPaused) {
      playFunction();
    } else {
      pauseFunction();
    }
  };

  const frameCounter = (time, metadata) => {
    let count = Math.round(metadata.mediaTime * fps);
    if (fps && frame != count) {
      setFrame(count);
      setMediaTime(metadata.mediaTime);
    }
    // Capture code here.
    video.requestVideoFrameCallback(frameCounter);
  };

  video?.requestVideoFrameCallback(frameCounter);

  return (
    <div className="sticky w-full top-[113px]">
      <div className="flex justify-between items-center h-[40px] my-2">
        <div className="">
          {isToggleVisible && (
            <Switch
              onChange={(e) => setVideoType((videoType) => (videoType === 'wt' ? 'wo' : 'wt'))}
              checked={videoType === 'wt'}
              label={t('common:showPrediction')}
            />
          )}
        </div>
        <Tooltip
          overlay={<ShortcutsInfo />}
          trigger={['hover', 'focus', 'click']}
          placement="leftTop"
          id="tooltip1"
        >
          <FaRegKeyboard className="mr-3 cursor-pointer h-5 w-5" />
        </Tooltip>
      </div>
      <div
        className={cls(`group relative player_container h-[calc(40vw-1rem)]`, {
          '[&&]:h-[calc(30vw-1rem)]': isSidePanelOpen
        })}
      >
        <video
          onClick={handleVideoClick}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onMouseMove={handleMouseMove}
          onTouchMove={handleTouchMove}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleMouseUp}
          onKeyDown={handleKeydown}
          ref={videoRef}
          className="shadow-lg player"
          muted
          style={reportMode ? { cursor: 'crosshair' } : {}}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        {isVideoLoading && <Loader height="0" type="absolute" />}
        {!isVideoLoading && <ControlsToolbar videoRef={videoRef} />}
        {reportMode && video?.paused && frames[frame]?.length > 0 && (
          <BoundedBoxes offsetWidth={offsetWidth} offsetHeight={offsetHeight} frame={frame} />
        )}

        {!isPaused && videoRef?.current?.playbackRate && (
          <Speed videoRef={videoRef} speed={videoRef?.current?.playbackRate} />
        )}
        {initialPoints && finalPoints && isDrawing && (
          <Box
            left={finalPoints.x - initialPoints.x < 0 ? finalPoints.x : initialPoints.x}
            top={finalPoints.y - initialPoints.y < 0 ? finalPoints.y : initialPoints.y}
            width={Math.abs(finalPoints.x - initialPoints.x)}
            height={Math.abs(finalPoints.y - initialPoints.y)}
          />
        )}
      </div>
      {videoRef?.current && <Controls videoRef={videoRef} />}
    </div>
  );
};

export default VideoPlayer;
