import { useAtom } from 'jotai';
import { Button, Input } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { isLoadingAtom, notificationAtom, appUserAtom } from '../../atoms/app';
import { uploadIdAtom } from '../../atoms/video';
import { registerVideo } from '../../api/video';
import {
  formEmailsArray,
  isAtleastOneInvalidEmail,
  isValidEmail,
  isPhoneValid
} from '../../utils/validation';
import { countries } from '../../helpers/constants';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import FormError from '../../components/FormError/FormError';

const InfoForm = ({ setActiveStep }) => {
  const { t } = useTranslation(['common', 'buttons']);
  const [appUser, setAppUser] = useAtom(appUserAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [uploadId, setUploadId] = useAtom(uploadIdAtom);
  const { user_name, user_email } = appUser || {};
  const splittedName = user_name?.split(' ') || ['', ''];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: { email: user_email, firstName: splittedName[0], lastName: splittedName[1] }
  });

  const onSubmit = async (formData, e) => {
    try {
      setIsLoading(true);
      const data = {
        dept_name: formData.department,
        dev_alias: formData.deviceAlias,
        insp_name: formData.firstName + ' ' + formData.lastName,
        email: formData.email,
        phone: formData.phone,
        share_reports: formEmailsArray(formData.emails),
        end_producer: formData.endoscopeProducer,
        end_model: formData.endoscopeModel,
        end_serial: formData.endoscopeSerial,
        end_length: formData.endoscopeLength
      };

      const res = await registerVideo(data);
      setUploadId(res?.video_id);
      setIsLoading(false);
      setActiveStep(1);
    } catch (e) {
      setIsLoading(false);
      console.log('submit error', e);
      setNotification({ isOpen: true, type: 'failure', message: t('common:somethingWrong') });
    }
  };

  return (
    <form className="px-4 mx-14 flex flex-col" noValidate onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-blue-main font-semibold my-6">{t('common:departmentInfo')}:</h3>
      <div className="flex justify-between">
        <div className="md:w-8/12 lg:w-5/12">
          <div className="relative mb-4">
            <Input
              required
              id="department"
              color="blue"
              label={t('common:department')}
              {...register('department', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.department?.type === 'required'}
            />
          </div>
          <h3 className="text-blue-main font-semibold my-6">{t('common:inspectorInfo')}:</h3>
          <div className="relative mb-4">
            <Input
              required
              id="firstName"
              color="blue"
              label={t('common:firstName')}
              {...register('firstName', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.firstName?.type === 'required'}
            />
          </div>
          <div className="relative mb-4">
            <Input
              required
              id="lastName"
              color="blue"
              label={t('common:lastName')}
              {...register('lastName', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.lastName?.type === 'required'}
            />
          </div>
          <div className="relative mb-6">
            <Input
              required
              color="blue"
              label={t('common:email')}
              {...register('email', {
                required: { value: true, message: t('common:fieldRequired') },
                validate: { email: (value) => isValidEmail(value) || t('common:emailInvalid') }
              })}
            />
            <FormError text={errors?.email?.message} isVisible={errors?.email} />
          </div>
          <div className="relative mb-4">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput {...field} ref={null} countries={countries} onChange={field.onChange} />
              )}
              rules={{
                required: { value: true, message: t('common:fieldRequired') },
                validate: {
                  isValid: (value) => isPhoneValid(value) || t('common:phoneNotValid')
                }
              }}
            />
            <FormError text={errors?.phone?.message} isVisible={errors?.phone} />
          </div>
          <div className="relative mb-4">
            <Input
              id="emails"
              color="blue"
              variant="outlined"
              label={t('common:shareReportWith')}
              placeholder={t('common:addEmailPeople')}
              multiple
              {...register('emails', {
                validate: {
                  pattern: (value) => !isAtleastOneInvalidEmail(value) || t('common:emailsInvalid')
                }
              })}
            />
            <FormError text={t('common:emailsInvalid')} isVisible={errors?.emails} />
          </div>
        </div>
        <div className="md:w-8/12 lg:w-5/12">
          <div className="mb-4">
            <Input
              required
              id="deviceAlias"
              color="blue"
              placeholder={t('common:whatModel')}
              label={t('common:recordingDeviceAlias')}
              {...register('deviceAlias', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.deviceAlias?.type === 'required'}
            />
          </div>
          <h3 className="text-blue-main font-semibold my-6">{t('common:endoscopeInfo')}:</h3>
          <div className="relative mb-4">
            <Input
              required
              id="endoscopeProducer"
              color="blue"
              label={t('common:endoscopeProducer')}
              {...register('endoscopeProducer', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.endoscopeProducer?.type === 'required'}
            />
          </div>
          <div className="relative mb-4">
            <Input
              required
              id="endoscopeModel"
              color="blue"
              label={t('common:endoscopeModel')}
              {...register('endoscopeModel', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.endoscopeModel?.type === 'required'}
            />
          </div>
          <div className="relative mb-4">
            <Input
              required
              id="endoscopeSerial"
              color="blue"
              label={t('common:endoscopeSerial')}
              {...register('endoscopeSerial', { required: true })}
            />
            <FormError
              text={t('common:fieldRequired')}
              isVisible={errors?.endoscopeSerial?.type === 'required'}
            />
          </div>
          <div className="relative mb-4">
            <Input
              id="endoscopeLength"
              color="blue"
              placeholder={t('common:lookAtInsertionTube')}
              label={t('common:endoscopeLength')}
              {...register('endoscopeLength')}
            />
          </div>
        </div>
      </div>
      <Button
        className="self-end"
        type="submit"
        variant="gradient"
        color="light-blue"
        disabled={false}
      >
        {t('buttons:continue')}
      </Button>
    </form>
  );
};

export default InfoForm;
