import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { modalAtom, isLoadingAtom } from '../../../atoms/app';
import Loader from '../../Loader';
import CloseButton from '../../CloseButton/CloseButton';

const RemoveModal = ({ handleRemove, handleClose, size, bodyText = 'actionIrreversible' }) => {
  const { t } = useTranslation(['common', 'buttons']);

  const [modal, setModal] = useAtom(modalAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const { name, isOpen } = modal;

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen && name === 'remove'} size={size || 'sm'} handler={handleClose}>
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <DialogHeader className="text-red-600 justify-between">
                {t('common:warning')} <CloseButton handleClose={handleClose} />
              </DialogHeader>
              <DialogBody className="text-lg">{t(`common:${bodyText}`)}</DialogBody>
              <DialogFooter>
                <Button variant="gradient" color="red" onClick={handleClose} className="mr-6">
                  {t('buttons:cancel')}
                </Button>
                <Button variant="gradient" color="green" onClick={handleRemove}>
                  {t('buttons:confirm')}
                </Button>
              </DialogFooter>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default RemoveModal;
