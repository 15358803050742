import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavListMenu from './NavListMenu';

const NavList = ({ selectedCompany }) => {
  const { t } = useTranslation(['menu']);

  const companyVideoUrl = '/videos/' + selectedCompany;
  return (
    <nav>
      <ul className="flex items-start flex-col p-4 pl-10 mt-4 font-medium border border-gray-100  bg-gray-50 2xl:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 mb-6 2xl:mt-0 2xl:mb-0 2xl:flex-row 2xl:p-1 2xl:items-center">
        <li>
          <Link
            to={`/company/${selectedCompany}`}
            className="block py-2 px-3  bg-blue-700 rounded bg-transparent text-blue-700 hover:text-blue-500"
            aria-current="page"
          >
            {t('menu:company')}
          </Link>
        </li>
        <li>
          <Link
            to={'/user-list'}
            className="block py-2 px-3  bg-blue-700 rounded bg-transparent text-blue-700 hover:text-blue-500"
            aria-current="page"
          >
            {t('menu:users')}
          </Link>
        </li>
        <li>
          <Link
            to={companyVideoUrl}
            className="block py-2 px-3 bg-blue-700 rounded bg-transparent text-blue-700 hover:text-blue-500"
            aria-current="page"
          >
            {t('menu:videos')}
          </Link>
        </li>
        <li>
          <NavListMenu />
        </li>
        <li>
          <Link
            to={'/video-upload'}
            className="block py-2 px-3 bg-blue-700 rounded bg-transparent text-blue-700 hover:text-blue-500"
            aria-current="page"
          >
            {t('menu:uploadVideo')}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavList;
